import 'axios';
import axios from 'axios';
import state from '@/store';
import { pruneOldPlayers } from './storage.js';

const api = axios.create({
    baseURL: 'https://census.daybreakgames.com/s:eq2cmp/json/get/eq2',
    timeout: 15000
});
const apiProxy = axios.create({
    // baseURL: location.hostname == 'localhost' ? 
    //     'http://localhost:5121/json/get/eq2' :
    //     'https://eq2reappapi.mooo.com/json/get/eq2',
    baseURL: 'https://eq2reappapi.mooo.com/json/get/eq2',
    timeout: 15000
});

function round(value, digits = 0) {
    return parseFloat(value.toFixed(digits));
}

async function fetchDataAsync(url) {
    var data = null;

    var proxyRetries = 0;
    const maxProxyRetries = 3;
    do {
        try {
            var apiData = await apiProxy.get(url);
            data = apiData.data;
        }
        catch {
            proxyRetries++;
            if (proxyRetries <= maxProxyRetries) {
                console.warn(`Retrying proxy (${proxyRetries}/${maxProxyRetries})`);
            }
        }
    } while (data == null && proxyRetries <= maxProxyRetries);

    if (data == null) {
        // Try the API direct
        console.warn("Proxy is unresponsive, attempting direct");
        try {
            apiData = await api.get(url);
            data = apiData.data;
        }
        catch (err) {
            console.error(err);
            throw "service_unavailable";
        }
    }

    return data;
}

export async function getItemStatsAsync(itemId) {
    if (!itemId) {
        return {};
    }

    let itemStats = state.getters.getItem(itemId);
    if (!itemStats) {
        itemStats = (await fetchDataAsync(`/item/${itemId}`))?.item_list?.[0]
        if (itemStats) {
            state.commit('addItem', itemStats);
        }
        else {
            console.error(`Unable to fetch item with id = ${itemId}`);
        }
    }

    return itemStats;
}

export async function getSpellAsync(spellId) {
    if (!spellId) {
        return '';
    }

    let spell = state.getters.getSpell(spellId);
    if (!spell) {
        spell = (await fetchDataAsync(`/spell/${spellId}`))?.spell_list?.[0]
        if (spell) {
            state.commit('addSpell', spell);
        }
        else {
            console.error(`Unable to fetch spell with id = ${spellId}`);
        }
    }

    return spell;
}

export async function getAchievementAsync(achievementId) {
    if (!achievementId) {
        return '';
    }

    let achievement = state.getters.getAchievement(achievementId);
    if (!achievement) {
        achievement = (await fetchDataAsync(`/achievement/${achievementId}`))?.achievement_list?.[0]
        if (achievement) {
            // Normalize some fields, so they're identical whether they come from the api
            // or local storage
            achievement.description = achievement.desc;
            achievement.steps = achievement.event_list ?? [];
            achievement.stepCount = (achievement.event_list ?? []).length;

            // Save it so it can be retrieved from cache
            state.commit('addAchievement', {
                name: achievement.name,
                description: achievement.description,
                id: achievement.id,
                steps: achievement.steps,
                stepCount: achievement.stepCount
            });
        }
        else {
            console.error(`Unable to fetch achievement with id = ${achievementId}`);
        }
    }

    return achievement;
}

export async function listAchievementsAsync(searchText) {
    const achievements = [];
    if (searchText) {
        let achievementList = null;
        if (isNaN(searchText)) {
            achievementList = await fetchDataAsync(`/achievement/?name=*${encodeURIComponent(searchText)}&c:limit=100&c:case=false&c:sort=name`);
        }
        else {
            achievementList = await fetchDataAsync(`/achievement/?id=${encodeURIComponent(searchText)}&c:limit=100&c:sort=name`);
        }
        for (const item of achievementList?.achievement_list ?? []) {
            achievements.push({
                name: item.name,
                description: item.desc,
                id: item.id,
                steps: item.event_list ?? [],
                stepCount: (item.event_list ?? []).length
            });
        }
    }
    return achievements;
}

export async function getCollectionAsync(collectionId) {
    if (!collectionId) {
        return '';
    }

    let collection = state.getters.getCollection(collectionId);
    if (!collection) {
        collection = (await fetchDataAsync(`/collection/${collectionId}`))?.collection_list?.[0]
        if (collection) {
            // Normalize some fields, so they're identical whether they come from the api
            // or local storage
            collection.items = collection.reference_list ?? [];
            collection.itemCount = (collection.reference_list ?? []).length;

            // Save it so it can be retrieved from cache
            state.commit('addCollection', {
                name: collection.name,
                category: collection.category,
                id: collection.id,
                items: collection.items,
                itemCount: collection.itemCount
            });
        }
        else {
            console.error(`Unable to fetch collection with id = ${collectionId}`);
        }
    }

    return collection;
}

export async function listCollectionsAsync(searchText) {
    const collections = [];
    if (searchText) {
        let collectionList = null;
        if (isNaN(searchText)) {
            collectionList = await fetchDataAsync(`/collection/?name=*${encodeURIComponent(searchText)}&c:limit=100&c:case=false`);
        }
        else {
            collectionList = await fetchDataAsync(`/collection/?id=${encodeURIComponent(searchText)}&c:limit=100`);
        }
        for (const item of collectionList?.collection_list ?? []) {
            collections.push({
                name: item.name,
                category: item.category,
                id: item.id,
                items: item.reference_list ?? [],
                itemCount: (item.reference_list ?? []).length
            });
        }
    }
    return collections;
}

function getEffectValFromDescription(desc, effectPrefix, valPrefix) {
    if (desc.startsWith(effectPrefix)) {
        return parseInt(desc.substring(desc.indexOf(valPrefix) + valPrefix.length).replace(",", ""));
    }
    return 0;
}

function getItemEffectValEquipped(item, effectPrefix, valPrefix) {
    return getItemEffectVal(item, effectPrefix, valPrefix, 'Equipped');
}

function getItemEffectValActivated(item, effectPrefix, valPrefix) {
    return getItemEffectVal(item, effectPrefix, valPrefix, 'Activated');
}

function getItemEffectVal(item, effectPrefix, valPrefix, qualifier = '') {
    var val = 0;

    const effects = item.effect_list ?? [];
    var qualifierMet = false;
    for (const effect of effects) {
        const desc = effect.description;
        const level = effect.indentation ?? 0;

        if (level == 0) {
            val = getEffectValFromDescription(desc, effectPrefix, valPrefix);
            if (val > 0) {
                break; // Return what we found
            }

            // Check if this is an activated or equipped statement
            qualifierMet = desc.includes(qualifier);
        }
        else if (level == 1 && qualifierMet) {
            val = getEffectValFromDescription(desc, effectPrefix, valPrefix);
            if (val > 0) {
                break; // Return what we found
            }
        }
    }
    return round(val);
}

function hasItemEffect(item, effectName) {
    for (const effect of item.effect_list ?? []) {
        const desc = effect.description;
        if (effectName == desc)
            return true;
    }
    return false;
}

function getCharacterSearchStrByName(server, name) {
    var serverNormalized = server.substring(0, 1).toUpperCase() + server.substring(1);
    var nameNormalized = name.toLowerCase();
    return `/character/?name.first_lower=${nameNormalized}&locationdata.world=${serverNormalized}&c:limit=2&c:sort=type.level:-1`;
}

function getCharacterSearchStrById(id) {
    return `/character/?id=${id}`;
}

const currentAdornColours = [
    'purple',
    'cyan',
    'white',
    'green',
    'black'
]
const rejectedAdornIds = [
    4247388578, // Elddar Hero's Focus
]
const allowedAdornIds = [
    2670388949,     // Ebb (BoZ)
    103004015,      // Flow (BoZ)
    4025894516,     // Sovereigns
    989323970,      // Sovereigns
    264900342,      // Zimaran Rune: Zeal
    4061330402,     // Coldforged Rune: Zeal
    1011465144,     // Spirit of Drakkel
    263283712,      // Spiritstone of Collected Ballads
    4079471332,     // Spiritstone of Zimaran Achievement,
    2815047672,     // Spiritstone of Coldforged Achievement
    1008423414,     // Spiritstone of Collected Scars
    1435149909,     // Well Prepared,
    187080935,      // Change of the Mad Lich
    3550867028,     // Observation of the Mad Lich
    1514995951,     // A Dream for All
    3579697230,     // Sodden Impact
    1513783773,     // Wasted Triumph
    326032726,      // Raid Rune: Raged Mentality I
    2206803983,     // Raid Rune: Bolsterous Mentality I
    1239281123,     // Raid Rune: Flowing Heals I
    2047894657,     // Raid Rune: Mountainous I
]
const allowedWhiteAdornPrefixes = [
    'Incandescent ',
    'Plateaus Nail ',
    'Zimaran ',
    'White Darkpaw ',
    'Tishan\'s Coldforged ',
    'Coldforged Adornment: ',
]
const allowedBlackAdornPrefixes = [
    'Coldforged Immunity ',
]
const allowedPurpleAdornPrefixes = [
    'The Collector\'s ',
    'Brightfeather ',
    'Darkfeather ',
    'Zimaran Rune: ',
    'Purple Darkpaw ',
    'Metis ',
    'Miragul\'s Miracle ',
    'Coldforged ',
    'Grim Claw ',
    'Vivid Fang ',
    'Constance ',
    'Galena ',
    'Esmer ',
    'Hallow Victory',
    'Tishan\'s Coldforged ',
    'Purple Rune ',
]
const allowedTempAdornPrefixes = [
    '[Level 120] Minion\'s ',
    '[Level 125] Minion\'s ',
    'D\'Morte ',
    'Extended D\'Morte ',
    'Maintained D\'Morte ',
    'Rime ',
    'Extended Rime ',
    'Maintained Rime ',
    'Libant ',
    'Extended Libant ',
    'Maintained Libant ',
    'Ring ',
    'Extended Ring ',
    'Maintained Ring ',
    'Zimaran ',
    'Extended Zimaran ',
    'Maintained Zimaran ',
    'Arcanna\'Se ',
    'Extended Arcanna\'Se ',
    'Maintained Arcanna\'Se ',
    'Kartis ',
    'Extended Kartis ',
    'Maintained Kartis ',
    'Coldforged ',
    'Extended Coldforged ',
    'Maintained Coldforged ',
    'Cult of Vul ',
    'Extended Cult of Vul ',
    'Maintained Cult of Vul ',
]
function adornIsCurrent(adornStats) {
    if (rejectedAdornIds.includes(adornStats.id))
        return false;
    if (allowedAdornIds.includes(adornStats.id))
        return true;

    const colour = adornStats?.typeinfo?.color ?? '';
    if (colour == 'purple') {
        for (const prefix of allowedPurpleAdornPrefixes) {
            if (adornStats.displayname.startsWith(prefix)) {
                return true;
            }
        }
    }
    else if (colour == 'white') {
        for (const prefix of allowedWhiteAdornPrefixes) {
            if (adornStats.displayname.startsWith(prefix)) {
                return true;
            }
        }
    }
    else if (colour == 'black') {
        for (const prefix of allowedBlackAdornPrefixes) {
            if (adornStats.displayname.startsWith(prefix)) {
                return true;
            }
        }
    }
    else if (colour == 'temporary') {
        for (const prefix of allowedTempAdornPrefixes) {
            if (adornStats.displayname.startsWith(prefix)) {
                return true;
            }
        }       
    }

    return false;
}

export async function getPlayerIdAsync(server, name) {
    var char = (await fetchDataAsync(`${getCharacterSearchStrByName(server, name)}&c:show=id`))?.character_list?.[0] ?? {};
    return char.id ?? -1;
}

export async function getPlayerStatsByIdAsync(id) {
    const now = Date.now() / 1000.0;
    let stats = state.getters.getPlayerById(id);
    if (stats) {
        const fetchTime = stats.fetchTime ?? 0;
        if (fetchTime > now || (now - fetchTime) > (60 * 10)) { // 10 minutes
            stats = null;
        }
    }

    if (!stats) {
        stats = await getPlayerStatsAsync(getCharacterSearchStrById(id));
        stats.fetchTime = now;
        state.commit('addOrReplacePlayer', stats);
    }

    return stats;
}

export async function getPlayerStatsByNameAsync(server, name) {
    let stats = await getPlayerStatsAsync(getCharacterSearchStrByName(server, name));
    stats.fetchTime = Date.now();
    state.commit('addOrReplacePlayer', stats);

    // Cleanup old players
    pruneOldPlayers();

    return stats;
}

export function getEmptyPlayerStats() {
    return {
        id: "",
        name: "",
        server: "",
        class: "",
        guild: "",
        guildId: "",
        mount: "",
        mountId: "",
        mountTier: "",
        familiar: "",
        familiarId: "",
        familiarTier: "",
        merc: "",
        mercTier: "",
        mercBattalion: "",
        multiplier: 0,
        castSpeed: {
            total: 0
        },
        reuse: {
            total: 0
        },
        adc: {
            total: 0
        },
        resolve: {
            total: 0,
            gear: 0,
            other: 0
        },
        potency: {
            total: 0,
            mount: 0,
            mountBuff: 0,
            bardings: 0,
            familiar: 0,
            gear: 0,
            adorns: 0,
            foodDrink: 0,
            aas: 0,
            preorderPet: 0,
            flawless: 0,
            rorPuppy: 0,
            other: 0
        },
        cb: {
            total: 0,
            gear: 0,
            adorns: 0,
            merc: 0,
            foodDrink: 0,
            aas: 0,
            guildBuff: 421,
            divineBuff: 300,
            other: 0
        },
        cbOvercap: {
            total: 0,
            base: 5000,
            familiar: 0,
            mount: 0,
            merc: 0,
            gear: 0,
            adorns: 0,
            foodDrink: 0,
            aas: 0,
            flawless: 0,
            divineBuff: 150,
            other: 0
        },
        fervor: {
            total: 0,
            gear: 0,
            adorns: 0,
            merc: 0,
            flawless: 0,
            divineBuff: 46.5,
            familiarBuff: 0,
            other: 0
        },
        fervorOvercap: {
            total: 0,
            gear: 0,
            adorns: 0,
            merc: 0,
            foodDrink: 0,
            flawless: 0,
            divineBuff: 46.5,
            familiarBuff: 0,
            other: 0
        },
        adorns: {
            blue: [],
            red: [],
            purple: [],
            green: [],
            cyan: [],
            white: [],
            black: [],
            temporary: [],
            unknown: []
        },
        gear: {
            primary: getEmptyGearStats(),
            secondary: getEmptyGearStats(),
            head: getEmptyGearStats(),
            chest: getEmptyGearStats(),
            shoulders: getEmptyGearStats(),
            forearms: getEmptyGearStats(),
            hands: getEmptyGearStats(),
            legs: getEmptyGearStats(),
            feet: getEmptyGearStats(),
            left_ring: getEmptyGearStats(),
            right_ring: getEmptyGearStats(),
            ears: getEmptyGearStats(),
            ears2: getEmptyGearStats(),
            neck: getEmptyGearStats(),
            left_wrist: getEmptyGearStats(),
            right_wrist: getEmptyGearStats(),
            ranged: getEmptyGearStats(),
            waist: getEmptyGearStats(),
            cloak: getEmptyGearStats(),
            activate1: getEmptyGearStats(),
            activate2: getEmptyGearStats(),
            event_slot: getEmptyGearStats(),
        },
        achievements: [],
        collections: [],
        checkup: {
            emptyAdorns: 0,
            oldAdorns: 0,
            rendingAdorns: '',
            plumage: 'Unknown',
            messages: []
        },
        factions: {
            bozIron: 0,
            bozGold: 0
        }
    }
}

function getEmptyGearStats() {
    return {
        amount: 0,
        tier: '',
        id: 0,
        iconId: 0,
        name: '',
        cboc: false,
        adorns: { 
            red: {
                count: 0
            },
            blue: {
                count: 0
            },
            green: {
                count: 0
            },
            white: {
                count: 0
            },
            black: {
                count: 0
            },
            cyan: {
                count: 0
            },
            purple: {
                count: 0
            }
        }
    };
}

async function setBasicStatsAsync(stats, char) {
    stats.id = char.id;
    stats.name = char.name.first;
    stats.server = char.locationdata.world;
    stats.class = char.type.class;
    stats.guild = char.guild?.name ?? "";
    stats.guildId = char.guild?.id ?? 0;

    stats.castSpeed.total = char.stats?.ability?.spelltimecastpct ?? 0;
    stats.reuse.total = char.stats?.ability?.spelltimereusepct ?? 0;
    stats.adc.total = char.stats?.combat?.abilitydoubleattackchance ?? 0;

    stats.potency.total = char.stats?.combat?.basemodifier ?? 0;
    stats.cb.total = char.stats?.combat?.critbonus ?? 0;
    stats.fervor.total = char.stats?.combat?.fervor ?? 0;
    stats.resolve.total = char.stats?.combat?.resolve ?? 0;

    const mount = await getItemStatsAsync(char.equipped_mount?.id);
    stats.mount = mount.displayname;
    stats.mountId = char.equipped_mount?.id;
    stats.mountTier = mount.tier.toLowerCase();
    stats.potency.mount = getItemEffectValActivated(mount, "Increases Potency", " by ");
    stats.cbOvercap.mount = getItemEffectValActivated(mount, "Increases Crit Bonus Overcap", " by ");

    const familiar = await getItemStatsAsync(char.equipped_familiar?.id);
    let familiarMultiCboc = 0.09;
    let familiarMultiOther = 0.09;
    if ((familiar._extended?.discovered?.timestamp ?? 0) > 1715822909) {
        familiarMultiCboc = 0.09; // Mid BoZ
        familiarMultiOther = 0.18;
    }
    stats.familiar = familiar.displayname;
    stats.familiarId = char.equipped_familiar?.id;
    stats.familiarTier = familiar.tier.toLowerCase();
    stats.potency.familiar = getItemEffectValActivated(familiar, "Increases Potency", " by ");
    stats.potency.familiar += familiarMultiOther * stats.potency.familiar;
    stats.cbOvercap.familiar = getItemEffectValActivated(familiar, "Increases Crit Bonus Overcap", " by ");
    stats.cbOvercap.familiar += familiarMultiCboc * stats.cbOvercap.familiar;
}

function setGearStats(stats, equipList) {
    for (const slot of equipList) {
        const item = slot.item ?? {};

        // Base values
        stats.resolve.gear += item.modifiers?.resolve?.value ?? 0;
        stats.potency.gear += item.modifiers?.basemodifier?.value ?? 0;
        stats.cb.gear += item.modifiers?.critbonus?.value ?? 0;
        stats.cbOvercap.gear += item.modifiers?.critbonus_overcap?.value ?? 0;
        stats.fervor.gear += item.modifiers?.fervor?.value ?? 0;
        stats.fervorOvercap.gear += item.modifiers?.fervor_overcap?.value ?? 0;

        // Food/drink
        if (item.typeinfo?.name === 'food') {
            stats.potency.foodDrink += getItemEffectVal(item, "Increases Potency", " by ");
            stats.cb.foodDrink += getItemEffectVal(item, "Increases Crit Bonus of", " by ");
            stats.cbOvercap.foodDrink += getItemEffectVal(item, "Increases Crit Bonus Overcap", " by ");
            stats.fervor.foodDrink += getItemEffectVal(item, "Increases Fervor of", " by ");
            stats.fervorOvercap.foodDrink += getItemEffectVal(item, "Increases Fervor Overcap", " by ");
        }
        else {
            stats.potency.gear += getItemEffectValEquipped(item, "Increases Potency", " by ");
            stats.cb.gear += getItemEffectValEquipped(item, "Increases Crit Bonus of", " by ");
            stats.cbOvercap.gear += getItemEffectValEquipped(item, "Increases Crit Bonus Overcap", " by ");
            stats.fervor.gear += getItemEffectValEquipped(item, "Increases Fervor of", " by ");
            stats.fervorOvercap.gear += getItemEffectValEquipped(item, "Increases Fervor Overcap", " by ");
            stats.resolve.gear += getItemEffectValEquipped(item, "Increases Resolve", " by ");
        }

        // Resolve list
        if (Object.prototype.hasOwnProperty.call(stats.gear, slot.name ?? '')) {
            const cboc = (item.modifiers?.critbonus_overcap?.value ?? 0) > 500;
            stats.gear[slot.name] = {
                ...getEmptyGearStats(),
                ...{
                    amount: item.modifiers?.resolve?.value ?? 0,
                    tier: (item.tier ?? '').toLowerCase(),
                    id: item.id,
                    iconId: item.iconid,
                    name: item.displayname,
                    cboc: cboc,
                    isWeapon: slot.name == 'primary' || slot.name == 'secondary' || slot.name == 'ranged',
                    is2Hander: slot.name == 'primary' && ((item.typeinfo?.wieldstyle ?? '') == 'Two-Handed'),
                    isRelic: (item.unique_equipment_group?.text ?? '') == "RELIC",
                    isGreaterRelic: (item.unique_equipment_group?.text ?? '') == "GREATER RELIC"
                }
            };
        }

        // Plumage
        if (slot.name == 'event_slot') {
            if (getItemEffectValEquipped(item, "Increases Crit Bonus of", " by ") > 0) {
                stats.checkup.plumage = 'CB';
            }
            else if (getItemEffectValEquipped(item, "Increases Max Health of", " by ") > 0) {
                stats.checkup.plumage = 'Health';
            }
            else if (getItemEffectValEquipped(item, "Increases Ability Doublecast of", " by ") > 0) {
                stats.checkup.plumage = 'ADC';
            }
            else if (getItemEffectValEquipped(item, "Increases in-combat movement speed", " by ") > 0) {
                stats.checkup.plumage = 'Speed';
            }
            else if (getItemEffectValActivated(item, "Dispels", "Dispels ") > 0) {
                stats.checkup.plumage = 'Cures';
            }
        }
    }
}

async function setAdornStatsAsync(stats, equipList) {
    var setBonuses = {};
    for (const slot of equipList) {
        for (const adorn of slot.item?.adornment_list ?? []) {
            if (slot.name != 'event_slot') {
                const colour = adorn.color ?? 'unknown';

                // Count the slots
                if (Object.prototype.hasOwnProperty.call(stats.gear, slot.name)) {
                    if (Object.prototype.hasOwnProperty.call(stats.gear[slot.name].adorns, colour)) {
                        stats.gear[slot.name].adorns[colour].count++;
                    }
                }

                // Tally stats
                if (adorn.id) {
                    let adornStats = await getItemStatsAsync(adorn.id);
                    if (adornStats) {
                        // Consider the adorn outdated if it's a current colour, and
                        // it doesn't match our allow/ban list. This means that players wearing
                        // the contested neck won't be flagged, for example.
                        const isCurrent = adornIsCurrent(adornStats);
                        if (!isCurrent && currentAdornColours.includes(colour)) {
                            stats.checkup.oldAdorns++;
                            stats.checkup.messages.push(`Outdated adorn: ${slot.displayname}`);
                        }

                        // Setup and count the set bonuses. We'll tally their stats after we've iterated.
                        const setBonusId = adornStats.setbonus_info?.id ?? 0;
                        if (setBonusId != 0) {
                            if (Object.prototype.hasOwnProperty.call(setBonuses, setBonusId)) {
                                setBonuses[setBonusId].numItems++;
                            }
                            else {
                                setBonuses[setBonusId] = {
                                    'id': setBonusId,
                                    'name': adornStats.setbonus_info.desc,
                                    'numItems': 1,
                                    'stats': adornStats.setbonus_list ?? [],
                                    'isCurrent': isCurrent
                                };
                            }
                        }
                        let name = adornStats.displayname ?? 'unknown';
                        if (adornStats.id == 2363162467) {
                            name = 'Zimaran Rune: Back Room Dealings';
                        }
                        const setBonus = adornStats.setbonus_info?.displayname ?? '';
                        if (stats.adorns[colour]) {
                            stats.adorns[colour].push({
                                name: name,
                                id: adorn.id,
                                slot: slot.displayname,
                                set: setBonus,
                                current: isCurrent,
                                isRelic: (adornStats.unique_equipment_group?.text ?? '') == "RELIC",
                                isGreaterRelic: (adornStats.unique_equipment_group?.text ?? '') == "GREATER RELIC",
                            });
                        }

                        // Add the adorn stats
                        if (isCurrent) {
                            // Direct stats
                            if (adorn.spiritlevel && adornStats.growth_table) {
                                for (var level = 1; level <= adorn.spiritlevel; level++) {
                                    const levelKey = `level${level}`;
                                    if (Object.prototype.hasOwnProperty.call(adornStats.growth_table, levelKey)) {
                                        const tableVals = adornStats.growth_table[levelKey];
                                        stats.potency.adorns += tableVals?.basemodifier ?? 0;
                                        stats.cb.adorns += tableVals?.critbonus ?? 0;
                                        stats.cbOvercap.adorns += tableVals?.critbonus_overcap ?? 0;
                                        stats.fervor.adorns += tableVals?.fervor ?? 0;
                                        stats.fervorOvercap.adorns += tableVals?.fervor_overcap ?? 0;
                                    }
                                }
                            }
                            else {
                                stats.potency.adorns += adornStats.modifiers?.basemodifier?.value ?? 0;
                                stats.cb.adorns += adornStats.modifiers?.critbonus?.value ?? 0;
                                stats.cbOvercap.adorns += adornStats.modifiers?.critbonus_overcap?.value ?? 0;
                                stats.fervor.adorns += adornStats.modifiers?.fervor?.value ?? 0;
                                stats.fervorOvercap.adorns += adornStats.modifiers?.fervor_overcap?.value ?? 0;
                            }

                            // Effect stats
                            stats.potency.adorns += getItemEffectValEquipped(adornStats, "Increases Potency", " by ");
                            stats.cb.adorns += getItemEffectValEquipped(adornStats, "Increases Crit Bonus of", " by ");
                            stats.cbOvercap.adorns += getItemEffectValEquipped(adornStats, "Increases Crit Bonus Overcap", " by ");
                            stats.fervor.adorns += getItemEffectValEquipped(adornStats, "Increases Fervor of", " by ");
                            stats.fervorOvercap.adorns += getItemEffectValEquipped(adornStats, "Increases Fervor Overcap", " by ");

                            // Check for rending adorns
                            if (colour === 'purple') {
                                var rendingName = '';
                                if (name.includes('Noxious Rending')) {
                                    rendingName = 'Nox';
                                }
                                if (name.includes('Arcane Rending')) {
                                    rendingName = 'Arcane';
                                }
                                if (name.includes('Elemental Rending')) {
                                    rendingName = 'Elem';
                                }
                                if (name.includes('Anguish')) {
                                    rendingName = 'Anguish';
                                }
                                if (name.includes('Rending Torrent')) {
                                    rendingName = 'Torrent';
                                }
                                if (rendingName) {
                                    if (stats.checkup.rendingAdorns)
                                    {
                                        stats.checkup.rendingAdorns += ', ';
                                    }
                                    stats.checkup.rendingAdorns += rendingName;
                                }
                            }
                        }
                    }
                }
                else {
                    // Only consider it missing if the slot is current
                    if (currentAdornColours.includes(colour)) {
                        stats.checkup.emptyAdorns++;
                        stats.checkup.messages.push(`Missing adorn: ${slot.displayname}`);
                    }
                }
            }
        }
    }

    for (const colour in stats.adorns) {
        stats.adorns[colour].sort((a,b) => {
            const setCmp = a.set.localeCompare(b.set);
            return setCmp == 0 ? a.name.localeCompare(b.name) : setCmp;
        });
    }
    for (const setBonusId in setBonuses) {
        const setBonus = setBonuses[setBonusId];
        for (const setBonusStat of setBonus.stats) {
            const numRequired = setBonusStat.requireditems ?? 1;
            if (setBonus.numItems >= numRequired && setBonus.isCurrent) {
                stats.potency.adorns += setBonusStat.basemodifier ?? 0;
                stats.cb.adorns += setBonusStat.critbonus ?? 0;
                stats.cbOvercap.adorns += setBonusStat.critbonus_overcap ?? 0;
                stats.fervor.adorns += setBonusStat.fervor ?? 0;
                stats.fervorOvercap.adorns += setBonusStat.fervor_overcap ?? 0;

                // Examine effects
                for (const propName in setBonusStat) {
                    if (propName.startsWith('descriptiontag_')) {
                        stats.potency.adorns += getEffectValFromDescription(setBonusStat[propName], "Increases Potency", " by ");
                        stats.cb.adorns += getEffectValFromDescription(setBonusStat[propName], "Increases Crit Bonus of", " by ");
                        stats.cbOvercap.adorns += getEffectValFromDescription(setBonusStat[propName], "Increases Crit Bonus Overcap", " by ");
                        stats.fervor.adorns += getEffectValFromDescription(setBonusStat[propName], "Increases Fervor of", " by ");
                        stats.fervorOvercap.adorns += getEffectValFromDescription(setBonusStat[propName], "Increases Fervor Overcap", " by ");
                    }
                }
            }
        }
    }
}

function setAAStats(stats, aaList) {
    // Scan AAs - http://census.daybreakgames.com/json/get/eq2/alternateadvancement/?c:limit=9999
    for (const aa of aaList) {
        if (aa.id == 3127384430) { // Heroic tree
            stats.potency.aas += aa.tier * 2311.47;
        }
        if (aa.id == 593578196) { // Heroic tree
            stats.cb.aas += aa.tier * 29.55;
            stats.cbOvercap.aas += aa.tier * 44.32;
        }
        if (aa.id == 2646103995) { // Heroic tree
            stats.potency.foodDrink *= (1 + (aa.tier * 0.02));
            stats.cb.foodDrink *= (1 + (aa.tier * 0.02));
            stats.fervorOvercap.foodDrink *= (1 + (aa.tier * 0.02));
        }

        if (aa.id == 593578196) { // Heroic tree
            stats.cb.aas += aa.tier * 29.55;
            stats.cbOvercap.aas += aa.tier * 44.32;
        }

        // Rogue - Cunning Prowess
        if (aa.id == 3472231681) { // Heroic tree
            stats.potency.aas += aa.tier * 982.8;
        }
        // Rogue - Blackguard's Luck
        if (aa.id == 1241444978) { // Heroic tree
            stats.cb.aas += aa.tier * 29.3;
            stats.cbOvercap.aas += aa.tier * 65.97;
        }
        // Crusader - Fervor of Faith
        if (aa.id == 4109369949) { // Heroic tree
            stats.cb.aas += aa.tier * 11;
        }
        // Summoner - Wild Channeling
        if (aa.id == 907696401) { // Heroic tree
            stats.cb.aas += aa.tier * 17.96;
        }
        // Enchanter - Savant's Channel
        if (aa.id == 1171275676) { // Heroic tree
            stats.cb.aas += aa.tier * 9.16;
        }
        // Enchanter - Volatile Magic
        if (aa.id == 852954890) { // Heroic tree
            stats.potency.aas += aa.tier * 5896.8;
        }
        // Cleric - Inspired Renewal
        if (aa.id == 4127882311) { // Heroic tree
            stats.cb.aas += aa.tier * 21.99;
        }
    }
}

function setFlawlessStats(stats, spellList, achievementList, equipList) {
    // Scan spells - http://census.daybreakgames.com/json/get/eq2/spell?name=^Holy&c:limit=100
    let hasVoVFlawless = false;
    let hasRoRFlawless = false;
    let hasBoZFlawless = false;
    for (const spell of spellList) {
        switch (spell.id) {
            case 2041924408:
                hasVoVFlawless = true;
                break;
            case 4215218770:
                hasRoRFlawless = true;
                break;
            case 165116927:
                hasBoZFlawless = true;
                break;
        }
    }

    // Check achievements for bonuses to flawless buffs and gear upgrades
    // http://census.daybreakgames.com/json/get/eq2/achievement?name=^Defeat+All&c:limit=100
    var vovBonus = 0;
    var rorBonus = -0.33; // The first one to give the bonus won't give the extra 33%
    var bozBonus = 0;
    stats.achievements = achievementList;
    for (const achievement of stats.achievements) {
        if (achievement.completed_timestamp != 0) {
            if (achievement.id == 3777274946) { // Triumph: Visions of Vehemence
                vovBonus += 0.1;
            }
            else if (achievement.id == 2681141655) { // Triumph: Master of Vetrovia
                vovBonus += 0.1;
            }
            else if (achievement.id == 1052518175) { // Defeat All First Tier Difficulty Raid Bosses
                vovBonus += 0.1;
            }
            else if (achievement.id == 2813687461) { // Defeat All Second Tier Difficulty Raid Bosses
                vovBonus += 0.1;
            }
            else if (achievement.id == 3501344307) { // Defeat All Third Tier Difficulty Raid Bosses
                vovBonus += 0.2;
            }
            else if (achievement.id == 1322701712) { // Defeat All Fourth Tier Difficulty Raid Bosses
                vovBonus += 0.2;
            }
            else if (achievement.id == 691032229) { // Defeat All Raid Bosses
                vovBonus += 0.2;
            }
            else if (achievement.id == 2181637900) { // Amok in the desert
                stats.potency.rorPuppy = 6538;
            }
            else if (achievement.id == 1010649208) { // Triumph: Save it for Desert [Heroic III]
                rorBonus += 0.33;
            }
            else if (achievement.id == 4176831985) { // Triumph: Progressing Flawlessly I
                rorBonus += 0.33;
            }
            else if (achievement.id == 331538162) { // Triumph: Progressing Flawlessly II
                rorBonus += 0.33;
            }
            else if (achievement.id == 4227904972) { // Triumph: Progressing Flawlessly III
                rorBonus += 0.33;
            }
            else if (achievement.id == 517779125) { // Triumph: Progressing Flawlessly IV
                rorBonus += 0.33;
            }
            else if (achievement.id == 2798032151) { // Triumph: Couplet Cacophony
                bozBonus += 0.25;
            }
            else if (achievement.id == 538069026) { // Live Free or Sky Hard
                bozBonus += 0.25;
            }
            else if (achievement.id == 570518067) { // Triumph: Flawlessly Defeat all Ballads of Zimara Tier 1 Raid Bosses
                bozBonus += 0.25;
            }
            else if (achievement.id == 3375812912) { // Triumph: Flawlessly Defeat all Ballads of Zimara Tier 2 Raid Bosses
                bozBonus += 0.25;
            }
            else if (achievement.id == 653572622) { // Triumph: Flawlessly Defeat all Ballads of Zimara Tier 3 Raid Bosses
                bozBonus += 0.25;
            }
            else if (achievement.id == 3290997111) { // Triumph: Flawlessly Defeat all Ballads of Zimara Tier 4 Raid Bosses
                bozBonus += 0.25;
            }
            else if (achievement.id == 736807497) { // Triumph: Flawlessly Defeat all Ballads of Zimara Raid Bosses
                bozBonus += 0.50;
            }
        }
    }

    // Check for BoZ charms
    for (const slot of equipList) {
        const item = slot.item ?? {};
        if (slot.name.startsWith("activate") && hasItemEffect(item, "When obtained, this item will grant a character flag that contributes to the Ballads of Zimara: Flawless Execution effect.")) {
            bozBonus += 0.25;
        }
    }

    if (hasBoZFlawless) {
        stats.potency.flawless += 13118.2;
        stats.fervor.flawless += 215.8;
        stats.fervorOvercap.flawless += 215.8;
        stats.cbOvercap.flawless += 409.8;

        stats.potency.flawless *= (1 + bozBonus);
        stats.fervor.flawless *= (1 + bozBonus);
        stats.fervorOvercap.flawless *= (1 + bozBonus);
        stats.cbOvercap.flawless *= (1 + bozBonus);
    }
    else if (hasRoRFlawless) {
        stats.potency.flawless += 9938.0;
        stats.fervor.flawless += 160.4;
        stats.fervorOvercap.flawless += 160.4;
        stats.cbOvercap.flawless += 304.5;

        stats.potency.flawless *= (1 + rorBonus);
        stats.fervor.flawless *= (1 + rorBonus);
        stats.fervorOvercap.flawless *= (1 + rorBonus);
        stats.cbOvercap.flawless *= (1 + rorBonus);
    }
    else if (hasVoVFlawless) {
        stats.potency.flawless += 4953.1;
        stats.fervor.flawless += 79.7;
        stats.fervorOvercap.flawless += 79.7;

        stats.potency.flawless *= (1 + vovBonus);
        stats.fervor.flawless *= (1 + vovBonus);
        stats.fervorOvercap.flawless *= (1 + vovBonus);
    }
}

function setMercStats(stats, mercList) {
    let contributionRates = {
        celestial: [0, 0, 0, 0, 0, 4, 4, 4, 4, 4, 8, 8, 8, 8, 8, 12, 12, 12, 12, 12, 16],
        fabled: [0, 0, 0, 0, 0, 3, 3, 3, 3, 3, 6, 6, 6, 6, 6, 9, 9, 9, 9, 9, 12],
        legendary: [0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 4, 4, 4, 4, 4, 6, 6, 6, 6, 6, 8],
        treasured: [0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 4],
    }
    let contribution = {
        sod: 0,
        zimaran: 0
    };
    let maxBattalionBuffs = {
        sod: { 
            celestial: {
                fervor: 724.9,
                fervorOc: 330.3,
                cboc: 11694.8,
            },
            fabled: { // Estimates:
                fervor: (724.9 + 632) / 2.0,
                fervorOc: (330.3 + 288) / 2.0,
                cboc: (11694.8 + 10195.3) / 2.0,
            },
            legendary: {
                fervor: 632,
                fervorOc: 288,
                cboc: 10195.3,
            },
            treasured: {
                fervor: 590.1,
                fervorOc: 268.9,
                cboc: 9519.3,
            },
        },
        zimaran: { 
            celestial: {
                fervor: 407.3,
                fervorOc: 118.8,
                cboc: 6570.1,
            },
            fabled: {
                fervor: 380.3,
                fervorOc: 110.9,
                cboc: 6134.5,
            },
            legendary: {
                fervor: 355.0,
                fervorOc: 103.6,
                cboc: 5727.7,
            },
            treasured: {
                fervor: 331.5,
                fervorOc: 96.7,
                cboc: 5347.9,
            },
        }
    };
    let battalionScales = {
        sod: {
            fervor: [],
            fervorOc: [],
            cboc: [],
        },
        zimaran: {
            fervor: [],
            fervorOc: [],
            cboc: [],
        },
    };
    let scale = battalionScales.sod;
    for (let i = 0; i <= 20; i++) {
        if (i < 5) {
            scale.fervor[i] = 0;
            scale.fervorOc[i] = 0;
            scale.cboc[i] = 0;
        }
        else if (i < 10) {
            scale.fervor[i] = 2.411510313;
            scale.fervorOc[i] = 2.410948905;
            scale.cboc[i] = 2.411995215;
        }
        else if (i < 20) {
            scale.fervor[i] = 1.832869785;
            scale.fervorOc[i] = 1.832963374;
            scale.cboc[i] = 1.83312695;
        }
        else {
            scale.fervor[i] = 1.058866491;
            scale.fervorOc[i] = 1.058653846;
            scale.cboc[i] = 1.058841637;
        }
    }
    scale = battalionScales.zimaran;
    for (let i = 0; i <= 20; i++) {
        if (i < 5) {
            scale.fervor[i] = 0;
            scale.fervorOc[i] = 0;
            scale.cboc[i] = 0;
        }
        else if (i < 10) {
            scale.fervor[i] = 2.411684783;
            scale.fervorOc[i] = 2.414918415;
            scale.cboc[i] = 2.411967828;
        }
        else if (i < 20) {
            scale.fervor[i] = 1.832731027;
            scale.fervorOc[i] = 1.833628319;
            scale.cboc[i] = 1.833098637;
        }
        else {
            scale.fervor[i] = 1.05903648;
            scale.fervorOc[i] = 1.058206107;
            scale.cboc[i] = 1.05884079;
        }
    }

    let fervor = 0;
    let fervorOc = 0;
    let cboc = 0;
    let oldMerc = '';
    for (const merc of mercList) {
        if (merc.rank >= 5) {
            let battalionTiers = {
                sod: {
                    tier: ''
                },
                zimaran: {
                    tier: ''
                },
            };
            switch (merc.name) {
                case 'Mepep Muckmire':
                    battalionTiers.sod.tier = 'celestial';
                    break;
                case 'Draksis Splitscale':
                case 'Gharlog the Exiled':
                    battalionTiers.sod.tier = 'legendary';
                    break;
                case 'Keesha Truetune':
                case 'Koahcoo Kachoo':
                    battalionTiers.sod.tier = 'treasured';
                    break;
                case 'Construct of Contention':
                case 'Construct of Discipline':
                case 'Construct of Malady':
                case 'Construct of Annihilation':
                    battalionTiers.zimaran.tier = 'celestial';
                    break;
                case 'Arlon Thornblade':
                case 'Flydia Snotrot':
                case 'Zunimosso':
                case 'Oyida':
                    battalionTiers.zimaran.tier = 'fabled';
                    break;
                case 'Lobelia Stokeflaggel':
                case 'Oracle Zendica':
                case 'Zo\'Gaka':
                case 'Shapash Talmil':
                    battalionTiers.zimaran.tier = 'legendary';
                    break;
                case 'Merrick Flintknuckle':
                case 'Levelly Perryn':
                case 'Attriza Calico':
                case 'Deralar Wildleaf':
                case 'Hangar Rotstomper':
                        battalionTiers.zimaran.tier = 'treasured';
                    break;
                case 'Eidolon of Conflict':
                case 'Eidolon of Control':
                case 'Eidolon of Pain':
                case 'Eidolon of Discern':
                case 'Harun Sprywing':
                    if (merc.rank >= 20) {
                        oldMerc = merc.name;
                    }
                    break;
            }

            let tier = '';
            let battalion = '';
            if (battalionTiers.sod.tier) {
                tier = battalionTiers.sod.tier;
                battalion = 'sod';
            }
            else if (battalionTiers.zimaran.tier) {
                tier = battalionTiers.zimaran.tier;
                battalion = 'zimaran';
            }
            if (tier) {
                // Accumulate all merc contributions
                contribution[battalion] += contributionRates[tier][merc.rank];

                // Capture the highest merc's stats
                let maxBuff = maxBattalionBuffs[battalion];
                let scale = battalionScales[battalion];
                if (fervor < (maxBuff[tier].fervor / scale.fervor[merc.rank])) {
                    fervor = maxBuff[tier].fervor / scale.fervor[merc.rank];
                    fervorOc = maxBuff[tier].fervorOc / scale.fervorOc[merc.rank];
                    cboc = maxBuff[tier].cboc / scale.cboc[merc.rank];
                    stats.merc = merc.name;
                    stats.mercTier = tier;
                    stats.mercBattalion = battalion;
                }
            }
        }
    }

    let contributionMulti = 1;
    let contributionValue = contribution[stats.mercBattalion] ?? 0;
    if (contributionValue >= 100) {
        contributionMulti = 1.05;
    }
    else if (contributionValue >= 80) {
        contributionMulti = 1.04;
    }
    else if (contributionValue >= 60) {
        contributionMulti = 1.03;
    }
    else if (contributionValue >= 40) {
        contributionMulti = 1.02;
    }
    else if (contributionValue >= 20) {
        contributionMulti = 1.01;
    }

    console.log(stats.name + ' - Merc battalion', stats.mercBattalion);
    console.log(stats.name + ' - Merc contribution', contributionValue);
    stats.cbOvercap.merc = cboc * contributionMulti;
    stats.fervor.merc = fervor * contributionMulti;
    stats.fervorOvercap.merc = fervorOc * contributionMulti;

    // Still using an old merc? Just use a rough figure - we haven't tallied up contribution for old mercs
    if (contributionValue < 1 && oldMerc) {
        stats.cb.merc = 5410.7;
        stats.fervor.merc = 335.4;
        stats.fervorOvercap.merc = 97.9;
        stats.merc = oldMerc;
        stats.mercTier = 'celestial';
    }
}

function setInfusionAndPreorderBuffStats(stats, spellList) {
    // Scan spells - http://census.daybreakgames.com/json/get/eq2/spell?name=^Holy&c:limit=100
    let hasEthMountBuff = false;
    let hasTolanMountBuff = false;
    let hasVahShirMountBuff = false;
    let hasDmorteMountBuff = false;
    let hasKartisMountBuff = false;
    let hasVoVPreorderPet = false;
    let hasRoRPreorderPet = false;
    let hasBoZPreorderPet = false;
    let hasSoDPreorderPet = false;
    let hasMonolithicMercBuff = false;
    let hasDwarvenMercBuff = false;
    let hasNewCombineMercBuff = false;
    let hasArcannaMercBuff = false;
    let hasBloodEmberFamBuff = false;
    let hasJubliantFamBuff = false;
    let hasRosEthFamBuff = false;
    let hasRingScaleFamBuff = false;
    let hasBozSwagFamBuff = false;
    let hasSodFamBuff = false;
    for (const spell of spellList) {
        switch (spell.id) {
            case 2255337601:
                hasRoRPreorderPet = true;
                break;
            case 3791472682:
                hasVoVPreorderPet = true;
                break;
            case 3983252899:
                hasVoVPreorderPet = true;
                break;
            case 784766866:
                hasEthMountBuff = true;
                break;
            case 2344685604:
                hasTolanMountBuff = true;
                break;
            case 1287339257:
            case 2002782521:
                hasVahShirMountBuff = true;
                break;
            case 3979841333:
                hasDmorteMountBuff = true;
                break;
            case 3810118498:
                hasKartisMountBuff = true;
                break;
            case 3056575190:
                hasBoZPreorderPet = true;
                break;
            case 1009275491:
                hasSoDPreorderPet = true;
                break;
            case 2533355716:
                hasMonolithicMercBuff = true;
                break;
            case 3938848563:
                hasDwarvenMercBuff = true;
                break;
            case 3577442158:
                hasNewCombineMercBuff = true;
                break;
            case 524920345:
                hasArcannaMercBuff = true;
                break;
            case 727927015:
                hasBloodEmberFamBuff = true;
                break;
            case 2153494897:
                hasJubliantFamBuff = true;
                break;
            case 1276378189:
                hasRosEthFamBuff = true;
                break;
            case 198188759:
                hasRingScaleFamBuff = true;
                break;
            case 4024568590:
                hasBozSwagFamBuff = true;
                break;
            case 718142862:
                hasSodFamBuff = true;
                break;
        }
    }
    if (hasSoDPreorderPet) {
        stats.potency.preorderPet = 50056;
    }
    else if (hasBoZPreorderPet) {
        stats.potency.preorderPet = 32690;
    }
    else if (hasRoRPreorderPet) {
        stats.potency.preorderPet = 24766;
    }
    else if (hasVoVPreorderPet) {
        stats.potency.preorderPet = 6115;
    }

    // Add mount buff potency
    let mountBuff = 0;
    if (hasKartisMountBuff) {
        mountBuff = 0.35;
    }
    else if (hasDmorteMountBuff) {
        mountBuff = 0.30;
    }
    else if (hasVahShirMountBuff) {
        mountBuff = 0.25;
    }
    else if (hasTolanMountBuff) {
        mountBuff = 0.20;
    }
    else if (hasEthMountBuff) {
        mountBuff = 0.15;
    }
    console.log(stats.name + ' - Mount buff', (mountBuff * 100).toFixed());
    stats.potency.mountBuff = stats.potency.mount * mountBuff;
    stats.cbOvercap.mount *= (1 + mountBuff);

    let mercMultiplier = 1;
    if (hasArcannaMercBuff) {
        mercMultiplier = 1.4;
    }
    else if (hasNewCombineMercBuff) {
        mercMultiplier = 1.3;
    }
    else if (hasDwarvenMercBuff) {
        mercMultiplier = 1.25;
    }
    else if (hasMonolithicMercBuff) {
        mercMultiplier = 1.23;
    }
    stats.cb.merc *= mercMultiplier;
    stats.cbOvercap.merc *= mercMultiplier;
    stats.fervor.merc *= mercMultiplier;
    stats.fervorOvercap.merc *= mercMultiplier;
    console.log(stats.name + ' - Merc buff', ((mercMultiplier - 1) * 100).toFixed());

    let famMultiplier = 0;
    if (hasSodFamBuff) {
        famMultiplier = 0.2;
    }
    else if (hasBozSwagFamBuff) {
        famMultiplier = 0.15;
    }
    else if (hasRingScaleFamBuff) {
        famMultiplier = 0.125;
    }
    else if (hasBloodEmberFamBuff) {
        famMultiplier = 0.07;
    }
    else if (hasJubliantFamBuff) {
        famMultiplier = 0.0595;
    }
    else if (hasRosEthFamBuff) {
        famMultiplier = 0.0525;
    }
    const beforeFamBuffFervor = stats.fervor.total / (1 + famMultiplier);
    stats.fervor.familiarBuff = stats.fervor.total - beforeFamBuffFervor;
    stats.fervorOvercap.familiarBuff = stats.fervor.total - beforeFamBuffFervor;
    console.log(stats.name + ' - Familiar buff', (famMultiplier * 100).toFixed(2));
}

function setOtherStats(stats) {
    stats.resolve.other = stats.resolve.total - (
        stats.resolve.gear);
    stats.potency.other = Math.max(0, stats.potency.total - (
        stats.potency.mount +
        stats.potency.mountBuff +
        stats.potency.bardings +
        stats.potency.familiar +
        stats.potency.gear +
        stats.potency.adorns +
        stats.potency.foodDrink +
        stats.potency.aas +
        stats.potency.preorderPet +
        stats.potency.flawless +
        stats.potency.rorPuppy));
    stats.cb.other = Math.max(0, stats.cb.total - (
        stats.cb.gear +
        stats.cb.adorns +
        stats.cb.merc +
        stats.cb.foodDrink +
        stats.cb.aas +
        stats.cb.guildBuff +
        stats.cb.divineBuff));
    stats.fervor.other = Math.max(0, stats.fervor.total - (
        stats.fervor.gear +
        stats.fervor.adorns +
        stats.fervor.merc +
        stats.fervor.flawless +
        stats.fervor.divineBuff +
        stats.fervor.familiarBuff));
    stats.cbOvercap.total = stats.cbOvercap.base +
        stats.cbOvercap.mount +
        stats.cbOvercap.familiar +
        stats.cbOvercap.merc +
        stats.cbOvercap.gear +
        stats.cbOvercap.adorns +
        stats.cbOvercap.foodDrink +
        stats.cbOvercap.aas +
        stats.cbOvercap.flawless +
        stats.cbOvercap.divineBuff;
    stats.fervorOvercap.total = stats.fervorOvercap.gear +
        stats.fervorOvercap.adorns +
        stats.fervorOvercap.merc +
        stats.fervorOvercap.foodDrink +
        stats.fervorOvercap.flawless +
        stats.fervorOvercap.divineBuff +
        stats.fervorOvercap.familiarBuff;
}

function setFactions(stats, factions) {
    for (const faction of factions) {
        switch (faction.id) {
            case 720: // Golden Merchants Guild of the Alcazar
                stats.factions.bozGold = faction.value;
                break;
            case 721: // Iron Merchants Guild of the Alcazar
                stats.factions.bozIron = faction.value;
                break;
        }
    }
}

function calcPlayerMultiplier(stats) {
    // Calculate an "effectiveness multiplier", which aims to indicate the character's relative
    // strength against raid mobs. This is a purely subjective, mostly guesswork number based on
    // some empirical ovservations with raid parses and combat mitigation.
    // Assumptions:
    //  - Combat mitigation of ~900000, has the following effect:
    const cmitPot = 900000;
    const cmitCB = 29000;
    const cmitFerv = 0;
    //  - Typical raiding character stats (assuming buffs and temps):
    const raidPot = 1500000;
    const raidCB = 44000;
    const raidFerv = 1500;
    //  - Baseline character stats (static):
    const basePot = 1450000;
    // const baseCB = 30000;
    const baseFerv = 1000;
    // - Therefore:
    //    1% increase == 4500 Pot == 80 CB == 16 Fervor
    // - Also:
    //    - Baseline char is 1000 nominal
    //    - Use an inferred CB and CBOC value to avoid skewing for chars who've gone too far one way
    const raidBuffCboc = 1500;
    const raidBuffCb = 11000;
    const raidAdjustedCB = Math.min(stats.cbOvercap.total + raidBuffCboc, stats.cb.total + raidBuffCb);

    var potMult = Math.max(1,
        (stats.potency.total + (raidPot - basePot) - cmitPot) /
        (raidPot - cmitPot)
    );
    var cbMult = Math.max(1,
        (raidAdjustedCB - cmitCB) /
        (raidCB - cmitCB)
    );
    var fervMult = Math.max(1,
        (stats.fervor.total + (raidFerv - baseFerv) - cmitFerv) /
        (raidFerv - cmitFerv)
    );
    return 1000 * potMult * cbMult * fervMult;
}

async function getPlayerStatsAsync(characterSearchStr) {

    var stats = getEmptyPlayerStats();

    try {
        var apiUrl = `${characterSearchStr}&c:resolve=equipmentslots,spells,factions`;
        var result = await fetchDataAsync(apiUrl);
        if (result?.error) {
            throw result.error;
        }
        var c = result?.character_list?.[0];
        if (!c) {
            throw `Character could not be fetched: ${characterSearchStr}`;
        }

        await setBasicStatsAsync(stats, c);
        setGearStats(stats, c.equipmentslot_list)
        await setAdornStatsAsync(stats, c.equipmentslot_list);
        setAAStats(stats, c.alternateadvancements?.alternateadvancement_list ?? []);
        setFlawlessStats(stats, c.spell_list ?? [], c.achievements?.achievement_list ?? [], c.equipmentslot_list);
        setMercStats(stats, c.owned_mercenary_list ?? []);
        setInfusionAndPreorderBuffStats(stats, c.spell_list ?? []);
        setOtherStats(stats);
        setFactions(stats, c.faction_list ?? []);

        stats.multiplier = calcPlayerMultiplier(stats);

        // Now for collection data
        apiUrl = `/character_misc/?id=${stats.id}&c:show=collection_list`;
        result = await fetchDataAsync(apiUrl);
        const charList = result?.character_misc_list ?? [];
        if (charList.length > 0) {
            for (const collection of charList[0]?.collection_list ?? []) {
                var collectionData = {
                    id: collection.crc,
                    collectedIds: []
                };
                for (const item of collection.item_list) {
                    collectionData.collectedIds.push(item.crc);
                }
                stats.collections.push(collectionData);
            }
        }
    }
    catch (err) {
        console.error(err);
        throw err;
    }

    roundObjectVals(stats);
    return stats;
}

function roundObjectVals(obj) {
    for (var prop in obj) {
        if (typeof obj[prop] === "number") {
            obj[prop] = round(obj[prop]);
        }
        else if (typeof obj[prop] === "object") {
            roundObjectVals(obj[prop]);
        }
    }
}